import React, { PureComponent } from 'react';

class Terms extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="body-wrapper pampas">
                <h2>Terms and conditions</h2>
                <p>We at Ken’s Foods, LLC have designed this web site (www.kensfoodservice.com) to be informative yet fun. However, We have a few rules governing the use of our Site, which We have included below. Please read these Terms before You proceed – because by using our Site, You are indicating to Ken’s that You understand and agree to the terms. If for some reason You believe You cannot abide by our Terms, PLEASE do not use our Site, but do let us know which term(s) You found objectionable. Also, because We may modify our Terms at any time, You may want to check back from time-to-time to make sure You are still abiding by the current Terms.</p>
                <p>Ownership and Use of Our Site. As between the two of us, We own all the photos, software, images, and other materials displayed or used in our Site, and all associated intellectual property rights. We are licensing You the limited right to access, use and display these materials, but only in connection with your use of our Site. You may “print-out” out single copies of recipes and other information on our Site for your personal use, but otherwise, You may not copy, distribute, republish, upload, modify or transmit any materials from our Site. You also agree that You will use the Site and Materials, and access other web sites from our Site, only for lawful purposes and in a way that does breach the rights of any other person or company.</p>
                <p>Submissions of Materials and Ideas. You may provide us with remarks, suggestions, ideas, graphics, recipes or other information through our feedback and ideas feature at inquires@kensfoods.com. However, by submitting these materials and ideas, You are also assigning all of your rights in such materials and ideas to Ken’s. This means several things to You, a few of which We have listed below.</p>
                <p>We will have exclusive ownership of all present and future rights to the materials and ideas You submit. We can use them for any purpose – even in our products and advertising. And, if We do use them – or any similar idea or materials – We do not have to compensate You or anyone other person or company. Also, You represent that You have the right to submit the materials and ideas to us under these Terms, and that You assume full responsibility for the accurateness and appropriateness of the materials You submit.</p>
                <p>Information About Our Company and You. We at Ken’s are proud of our company. Any information We have provided about our facilities, capabilities, practices, standards or other information is provided only as general information about our company. None of this information is intended or shall be deemed be an offer of any product or service, or to serve as a term or condition in any later agreement between You (or any other person or company) and Ken’s.</p>
                <p>Product Information and Specifications. We may provide product information and/or specification sheets on our Site for information purposes only. While We endeavor to make this information as accurate as possible, We do not warrant or guarantee that product information or specifications are accurate, complete, reliable, current or error-free. If you have questions or would like more information regarding product details or ingredients, including nutritional facts and/or allergen information, please refer to the product label or contact us at 800-643-5800. We recommend that you always read labels, warnings, and notices before using or consuming a product.</p>
                <p>Our Site and Your Health. Any information available through use of our Site is general interest information and is not to be confused with, or used as, professional nutritional, health or medical advice. You (or anyone else) should always consult with your health care professional before You start or change a diet or exercise program, especially if You are under eighteen (18) of age, pregnant, nursing, or have health problems. Never disregard professional medical advice or delay in seeking it because of something You have read on this Site. And remember, what may help one person may harm another.</p>
                <p>Other Sites We provide links to other web sites from our Site. We do not endorse or approve any products, services or accuracy of any information or materials of any other web site, or that the links to any other web sites are current, active or correct.</p>
                <p>DISCLAIMER OF WARRANTIES. THE MATERIALS ON OUR SITE ARE PROVIDED “AS IS”. THIS MEANS THAT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, KEN’S DISCLAIMS ALL EXPRESS AND IMPLIED WARRANTIES ABOUT THE SITE AND ANY MATERIALS CONTAINED IN THE SITE. THIS MEANS THERE IS NO WARRANTY THAT THE SITE OR ANY SITE OR MATERIALS ACCESSED THROUGH THE SITE ARE FIT FOR A PARTICULAR PURPOSE, ARE OF ANY PARTICULAR LEVEL OF QUALITY (“MERCHANTABILITY”), OR THAT THERE IS GOOD TITLE TO THE SITE OR MATERIALS CONTAINED IN THE SITE.</p>
                <p>WE THINK OUR RECIPES ARE WONDERFUL (AND WE HOPE YOU DO TOO), BUT PLEASE NOTE THAT WE CANNOT AN DO NOT WARRANT OR PREDICT YOUR RESULTS OR OUTCOMES FROM FOLLOWING ANY INSTRUCTIONS, DIRECTIONS OR RECIPES OBTAINED THROUGH USE OF OUR SITE.</p>
                <p>LIMITATION OF LIABILITY. KEN’S IS NOT LIABLE TO ANY PERSON OR COMPANY FOR ANY DAMAGES OR INJURY THAT RESULT OR ARISE FROM USE OF THE SITE, THE MATERIALS AND INFORMATION AVAILABLE THROUGH USE OF OUR SITE OR ANY WEB SITE ACCESSED FROM OUR SITE.</p>
                <p>THESE LIMITATIONS APPLY TO ANY DAMAGES, LOSSES OR INJURY TO YOU OR ANY OTHER PERSON OR COMPANY, WHETHER PERSONAL OR TO PROPERTY, CAUSED BY ANY USE, FAILURE, INTERRUPTION, DEFECT, DELAY OR INABILITY TO USE THE SITE OR ANY WEB SITE ACCESSED THROUGH OUR SITE, OR ANY OMISSION, COMPUTER VIRUS, OR FAILURE IN TELECOMMUNICATIONS USED TO ACCESS THE SITE. FURTHERMORE, WE ARE NOT LIABLE EVEN IF WE’VE BEEN NEGLIGENT OR IF OUR AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
                <p>ALSO, ALL OF THESE EXCLUSIONS APPLY TO DAMAGES INTENDED TO COMPENSATE SOMEONE DIRECTLY FOR A LOSS OR INJURY, INDIRECT, CONSEQUENTIAL OR OTHER DAMAGES REASONABLY EXPECTED TO RESULT FROM A LOSS OR INJURY, OR OTHER INCIDENTAL EXPENSES OR COSTS ARISING FROM A LOSS OR INJURY.</p>
                <p>EXCEPTION: IN CERTAIN STATES, THE LAW MAY NOT ALLOW US TO DISCLAIM OR EXCLUDE WARRANTIES, OR LIMIT LIABILITY, SO THE ABOVE DISCLAIMER AND LIMITATION ON LIABILITY MAY NOT APPLY TO YOU.</p>
                <p>Term and Termination. We both recognize that our agreement to the Terms continues only for so long as we both mutually agree to continue it. Either You or Ken’s may terminate at any time and for any reason. If You terminate, You agree to destroy all copies of all materials You have obtained from the Site. If You breach any of the Terms, your rights under the Terms will terminate immediately.</p>
                <p>Miscellaneous. These Terms constitute the entire agreement between You and Ken’s with respect to the Site and supercedes any prior agreements or previous terms between You and Ken’s. Ken’s failure to enforce any provision of these Terms shall not be construed as a waiver of any provision or right. In the event that a portion of the Terms is held unenforceable, the unenforceable portion shall be construed in accordance with applicable law as nearly as possible to reflect the original intentions of the parties, and the remainder of the provisions shall remain in full force and effect. These Terms and our agreement shall be interpreted and governed by the laws of the State of Massachusetts and the United States. All of your obligations under the Terms, and all of the limitations of liability, disclaimers of warranties, ownership provisions, and other terms necessary to give legal construction, shall survive termination of the Terms by either You or Ken’s.</p>
                <p>Territory of Use and Export. Ken’s controls and operates company and Site from the company’s headquarters in Marlborough, Massachusetts, in the United States of America. We in no way represent that the materials on the Site are appropriate or available for use outside of the United States. If You use our Site from locations outside of the United States, You are responsible for compliance with any applicable local laws.</p>
                <p>4842-9402-4219.1</p>
            </div>
        );
    }
}

export default Terms;
